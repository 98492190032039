@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

body {
    @apply bg-background;
    font-family: 'Poppins', sans-serif;
}

.segment {
    @apply bg-white border border-neutral-300 shadow rounded p-4;
}

.form .title {
    @apply text-3xl block;
}

.form .input {
    @apply bg-white text-black;
    @apply p-2 flex;
    @apply border border-neutral-300 shadow rounded;
}
.form .input:disabled {
    @apply bg-neutral-100;
}
.form .disabled {
    @apply bg-neutral-100;
}
.form .input svg {
    @apply flex-none h-6 mr-1 text-neutral-500;
}
.form .input input {
    @apply bg-transparent border-0 flex w-full placeholder-neutral-500;
}

.form .error {
    @apply border border-red-600;
}
.form .error-msg {
    @apply text-red-600;
}


.table {
    @apply grid bg-white text-neutral-500 border border-neutral-300 shadow rounded p-4 overflow-hidden;
}
.table > .header {
    @apply py-2 px-2 font-extrabold text-lg text-black border-0;
}
.table > div {
    @apply p-2 py-4 font-normal border-t border-neutral-300;
}


h1 {
    @apply text-4xl;
}

h2 {
    @apply text-3xl mb-4;
}
h3 {
    @apply text-2xl;
}
